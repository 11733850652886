/* eslint-disable no-shadow */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from "react";
import { Range } from "react-range";
import {
  sortByOptions,
} from "./filterOptions";
import SimpleCustom, { option } from "../../tailwindui/application-ui/forms/select-menus/SimpleCustom";
import { safelyCallJSFunction } from "../visualisation-functions";

declare const window: any;
const isBrowser = typeof window !== "undefined";

const FilterPanel: React.FunctionComponent = () => {
  const START_YEAR = 2019;
  const END_YEAR = 2040;
  const [activeSortByOptions, setActiveSortByOptions] = useState(sortByOptions);
  const [, setSortByValue] = useState(1);
  const [value, setValue] = React.useState([START_YEAR]);

  const setSliderValue = (newValue: number[]) => {
    setValue(newValue);

    if (isBrowser) {
      const {aleph} = window;
      if (aleph) {
        aleph.dotYear = value;
        aleph.dotYearIndex = aleph.years.indexOf(aleph.dotYear.toString());
    
        // safelyCallJSFunction("transitionDotsChart_toNewYear");

        safelyCallJSFunction("getNewSelectedYear");
        
      }
    }

  }

  const sortByChanged = (opt: option) => {
    console.log("const sortByChanged:", opt,opt.value)
    setSortByValue(opt.value);
    safelyCallJSFunction("sortByChanged", opt.value);
  }

  if (isBrowser) {
    window.updateSortByOptions = (allowedAgeBands: string[]) => {
      const newOptions = [
        {value: 1, label: "Region"},
      ];
      allowedAgeBands.forEach((ageBand, i) => {
        newOptions.push({value: i+2, label: ageBand});
      })
      setActiveSortByOptions(newOptions);
    }
  }

  return (
    <section className="px-4 py-0 mx-auto md:px-8 md:py-0 z-50">
      <div className="flex">
        <div className="m-4">
          <SimpleCustom label="Sort By" options={activeSortByOptions} onChange={(opt) => {sortByChanged(opt);}} />
        </div>

        <div className="flex-1 mt-2 grid grid-cols-1 gap-2 px-4">
          <Range
            step={1}
            min={START_YEAR}
            max={END_YEAR}
            values={value}
            onChange={(value) => {
              setSliderValue(value)
            }}
            renderMark={({ props, index }) => (
              <div className="flex-col w-16 text-center justify-between" {...props}>
                <div className="flex-none mb-2 text-sm font-bold whitespace-nowrap">&nbsp;</div>
                <div className="flex-none mx-auto mt-2 h-3 w-1 bg-primary-600" />
                <div className="flex-none mt-2 text-sm whitespace-nowrap">
                  {(index === 0 || index + START_YEAR === END_YEAR || (index + START_YEAR) % 2 === 0 ) ? Number(index + START_YEAR) : <span>&nbsp;</span>}
                </div>
              </div>
            )}         
            renderTrack={({ props, children }) => (
              <div
                {...props}
                className="w-full h-3 pr-2 my-4 bg-gray-200 rounded-md"
              >
                {children}
              </div>
            )}
            renderThumb={({ props }) => (
              <div className="flex-col text-center" {...props}>
                <div className="flex-1 mt-6 mx-auto w-5 h-5 bg-primary-600 rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-400" />
                <div className="flex-1">&nbsp;</div>
              </div>
            )}
          />
        </div>
      </div>
    </section>
  )
}

export default FilterPanel;