/* eslint-disable import/prefer-default-export */

export const sortByOptions = [
  {value: 1, label: "Region"},
  {value: 2, label: "<16"},
  {value: 3, label: "16 and 17"},
  {value: 4, label: "18 and 19"},
  {value: 5, label: "20 to 24"},
  {value: 6, label: "25 to 34"},
  {value: 7, label: "35 to 44"},
  {value: 8, label: "45 to 54"},
  {value: 9, label: "55 to 66"},
  {value: 10, label: "67 and over"},
];
