/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-shadow */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable import/extensions */
import React from "react";
import withRoleRequired from '../../lib/withRoleRequired';
import visualisationSettings from "../../components/visualisations/visualisation-three/visualisationSettings";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import SideBar from "../../components/visualisations/sidebar";
import Visualisation from "../../components/visualisations/visualisation";
import FilterPanel from "../../components/visualisations/visualisation-three/filterPanel";

const DotViz: React.FunctionComponent = () => (
  <Layout
    pageHeader=""
    breadcrumbs={[{ label: 'Workforce Population Modelling', link: '/modelling' }]}
    moreInfo="modelling/visualisation-three"
  >
    <SEO
      keywords={[`dstl`, `future`, `workforce`, `modelling`, `dot`, `visualisation`]}
      title="Population Demography by Region and Age Band"
    />
    { /* For anything tablet or bigger */ }
    <div className="hidden md:block">
      <SideBar currentChart={3} />
      <FilterPanel />
      <Visualisation {...visualisationSettings} params={{}} />
    </div>
    { /* For phones */ }
    <div className="md:hidden">
      <img alt="Line Graph" src="/image/visualisations/dot.svg" />
      <div className="m-4 text-center">
            Visualisations cannot be viewed at this screen size
      </div>
    </div>  
  </Layout>
)

export default withRoleRequired(DotViz);
